button{
    display: inline-block;
    border-radius: 0.4em;
    padding: 0.5em 1em !important;
    color: #fff;
    background-color: var(--sec-color);
    border: 0;
    outline: none;
    margin-bottom: 5px;
}
@media(max-width:640px){
    button{
        padding: 0.1em 0.5em;
    }
}

button.xs{
    font-size: 0.75em;
}
button.md{
    font-size: 1.2em;
}
button.success{
    background-color: var(--success-color);
}
button.danger{
    background-color: var(--danger-color);
}
button.cancel{
    background-color:var(--pry-color-light);
}