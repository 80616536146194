.hidePage{
    margin-left:-120%;
    opacity:0;
    max-height: 0;
    height: 0px;
    float: left;
}
.page{
    width:100%;
    overflow: hidden;
    transition:margin-left 0.2s ease-in-out,opacity 0.4s ease-in-out;
}
.bookCarConfirmation{
    color:#000;
    text-align: center;
}
.bookCarConfirmation img{
    display: inline-block;
    text-align: center;
}
