.select{
    position: relative;
    display: inline-block;
    width: 100%;
}
.select>input{
    width:100%;
    cursor: pointer;
    background:url(../images/arrow_down.svg) no-repeat right;
    background-size: 15px;
}
.popUp input{display:none}
.select label{
    display:block;
    padding:10px;
    max-height: none;
    font-size: initial;
}
.select .popUp{
    display: inline-block;
    position: absolute;
    left:0;
    max-height: 0;
    overflow: hidden;
    top:0;
    background-color: #fff;
    color: #000;
    width: 100%;
    z-index: 1;
    border-radius: 8px;
    border: 0;
}
.popUp.show{
    max-height: none;
    box-shadow: 0px 2px 5px 2px rgba(214, 216, 224, 0.65);
}
.popUp label{
    padding: 15px 25px;
    border-bottom: 1px solid var(--pry-color-light);
    cursor: pointer;
}
.popUp label:hover{
    background-color: rgba(214, 216, 224, 0.65)
}
.popUp label:last-of-type{
    border-bottom:none;
}
@media(max-width:640px){
    button{
        padding: 0.1em 0.5em;
    }
}