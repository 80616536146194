@import url('https://use.typekit.net/uhs8nzw.css');

html{
  scroll-behavior:smooth;
}
body {
  margin: 0;
  padding: 0;
  font-weight: 300 !important;
  font-family: "proxima-nova", BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Ubuntu", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 1.5s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
