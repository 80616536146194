
.detailView .row{
    padding:100px 10%;
}
.detailView .jumbotron{
    background-color: var(--sec-color);
    border-radius: 0;
    padding: 50px 180px;
    position: relative;
}
.detailView .jumbotron label,.detailView .jumbotron p,.detailView .jumbotron h1,.detailView .jumbotron h2{
    color:#fff;
}
.detailView .jumbotron video{
    margin-top:-80px;
    width: 100%;
    overflow: hidden;
}
.detailView .jumbotron .videoWrapper{
    filter: brightness(50%);
    left:0;
    top:0px;
    position: absolute;
    display: inline-block;
}
.detailView h2{
    margin-bottom:40px;
    color:var(--pry-color);
    font-weight: 600;
    font-size: 2.5em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;
}
.detailView .no-padding{
    padding:0
}
.detailView .secondaryBg{
    background-color: #ff8a31;
    color:#fff;
}
.detailView .secondaryBg h2{
    color:#fff;
}
.detailView .noCommitment .row{
    padding-bottom:0;
}
.detailView .noCommitmentCaption{
    padding-top:100px;
}
.detailView .faq{
    background-color: whitesmoke;
}

.faqBody button{
    box-shadow:4px 4px 11px 0px #b6b8bdc2;
    padding-top: 20px !important;
    padding-bottom:20px !important;
}
.detailView .faqBody{
    padding:20px 0;
}
.detailView .testimonials .card{
    background-color: var(--pry-color);
    color:#fff;
    padding:30px;
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .detailView .jumbotron{
        padding:0;
    }
    .detailView .jumbotron video{
        margin-top:-20px;
    }
    .detailView .jumbotron .display-3{
        font-size: 2.3rem;
    }
    .detailView .jumbotron .display-4{
        font-size: 2rem;
    }
    .detailView .noCommitment .row{
        padding-bottom:100px;
    }
    
}