
.faqView.row{
    padding:100px 10%;
}
.faqView.row > div{
    padding:20px 0;
}
.nav-link.active{
    font-weight: 600;
}
.faqView.row .faqBody {
    padding-top:15px;
    padding-bottom:15px;
}