.mainNavbar{
    background-color: var(--sec-color);
    color:#fff;
    padding:1rem 15rem;
    z-index: 1;
    border-bottom: 4px solid #fff;
}
.mainNavbar .navbar-nav .nav-link{
    color:#fff;
    font-weight: 400;
    letter-spacing: 1px;
}
.mainNavbar .nav-link:hover{
    font-weight: 600;
}
@media (max-width:640px){
    .innerNav{
        display: inline-block;
    }
    .mainNavbar{
        padding:.5rem .5rem;
    }
}

