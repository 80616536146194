::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}

.modal-container{
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    width:100%;
    height:100%;
    opacity: 0;
    left:0;
    top:0;
    background: transparent;
    z-index: 2;
    overflow: hidden;
    -webkit-transform: scale(0);
    -webkit-transition: all 0.2s ease;
    -moz-transform: scale(0);
    -moz-transition: all 0.2s ease;
    transform: scale(0);
    transition: all 0.5s ease;
}

.modal-container.show{
    transform: scale(1);
    opacity: 1;
}
.modal-container::before{
    position: absolute;
    content: "";
    opacity: 0.8;
    top:0;
    left:0;
    background: #0e161d;
    width:100%;
    height: 100%;
}
.modal-body{
    display: inline-block;
    max-width:40%;
    max-height:90%;
    padding-top:30px;
    padding-left:30px;
    padding-right:30px;
    background: #fff;
    overflow-y: auto;
    box-shadow: -5px 5px 100px rgba(95, 103, 109, 0.19);
    border-radius: 20px;
}
@media(max-width:640px){
    .modal-body{
        max-width:90%;
    }
}
.modal-body .content{
    width: 100%;
    display: inline-block;
    position: relative;
}
.modal-container header{
    text-transform: uppercase;
    text-align: center;
    color:#808088;
    display: inline-block;width: 100%;
}