.carListView .item{
    padding-top:50px;
    padding-bottom:50px;
}
.carListView p,.carListView header,.carListView span{
    color:black;
}
.carListView p{
    font-weight: 400;
}
.carListView .title{
    text-align: left;
}
.carListView .item img{
    text-align: center;
    max-height: 150px;
    display: block;
    margin-bottom: 20px;
}
@media(max-width:640px){
    button{
        padding: 0.1em 0.5em;
    }
}