.mainFooter{
    padding:100px 10%;
}
.mainFooter .nav-link{
    padding-left:0;
    color:var(--pry-color);
    font-weight: 400;
}
.mainFooter .nav-link:hover{
    font-weight: 600;
}