.hidePage{
    margin-left:-120%;
    opacity:0;
    max-height: 0;
    height: 0px;
    float: left;
}
.page{
    width:100%;
    overflow: hidden;
    transition:margin-left 0.2s ease-in-out,opacity 0.4s ease-in-out;
}
.stepThree{
    text-align: center;
}

.secButton{
    color: #fff;
    background-color: var(--sec-color);
    border: 0;
    border-radius:4px;
    font-size:16px;
    font-weight: 400;
    padding:8px 16px;
    line-height: 1.5rem;
    outline: none;
}

.secButton:hover,.secButton:focus,.secButton:active{
    opacity: .8;
    background-color:var(--sec-color) !important;
}
.secButton.disabled{
    opacity: .8;
    background-color:var(--sec-color);
    cursor: not-allowed;
}

.progress-bar{
    height: 40px;
    padding: 8px;
    margin: 20px 0;
    font-size: 0.8em;
    border-radius: 8px;
    background-color: var(--sec-color);
}
.applicationForm p{
    color:var(--pry-color) !important;
}
.applicationForm .form-group p{
    line-height: 1.8em;
}
.applicationForm label{
    padding-left:0;
    line-height: 1.5rem;
}
.applicationForm .option{
    padding-left:0;
    line-height: 1.5rem;
}
.custom-file-label{
    padding:10px !important;
    line-height: 1rem !important;
}
