input{
    display: inline-block;
    padding: 7px 10px;
    margin-bottom:10px;
    color: #515861;
    border: 0;
    outline: none;
    border-bottom: 1px solid rgba(173, 182, 197, 0.38);
}
.error-state input{
    border-bottom-color:#ff2f4a;
}
.full-width{
    width:100%;
}

input:focus { 
   border-bottom: 4px solid rgba(173, 182, 197, 0.8);
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.7; /* Firefox */
}
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
opacity: 0.7;
}
  
::-ms-input-placeholder { /* Microsoft Edge */
opacity: 0.7;
}
label{
    display: inline-block;
    width:100%;
    color: #515861 !important;
    padding-left: 10px;
    margin-bottom:0;
    opacity: 0.8;
    font-weight: 500;
    font-size: 0.8em;
    max-height: 500px;
    overflow: hidden;
    transition-duration: 0.3s;
    transition-property: max-height;
}
label.hide{
    max-height: 0;
}
.error-state label{
    color:#ff2f4a !important;
}
