/****** Globall styling ******/

/*#################### BUGGY-HEADER ####################*/
.accordion{
margin:20px 0;
padding:0.05rem 1.0rem;
}
header{
    display:block;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}
.closed{
    max-height: 0;
}
.open{
    max-height: 100rem;
    margin-top:10px;
}
.accordion-content{
    box-sizing: content-box;
    height: auto;
    overflow: hidden;
    transition-duration: 0.3s;
    transition-property:all;
    z-index: 1;
    transition-timing-function: cubic-bezier(0.595, 0.13, 2, 3);
}