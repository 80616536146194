.mainContainer{
    padding:0;
    margin:0;
    font-size: 18px;
    color:var(--pry-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;
    line-height: 2.6em;
}
h1,h2,h3,h4,h5,h6{
    letter-spacing: 1px;
}
button{
    letter-spacing: 1px;
}
.btn-secondary{
    background-color: var(--pry-color);
    border: none;
    transition: 0.3s cubic-bezier(0.2,0.8,0.2,1);
}
.btn-secondary:hover{
    background-color:var(--pry-color);
    opacity: .8;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle{
    background-color:var(--pry-color);
}
a{
    color: var(--pry-color);
    transition: 0.3s cubic-bezier(0.2,0.8,0.2,1);
    cursor: pointer;
}

a:hover,.dropdown-item.active, .dropdown-item:active{
    font-weight: 600;
    color: inherit;
    background-color: inherit;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .hidden-xs{
        display: none;
    }
}
.mainContainer .modal-container::before{
    opacity: 1;
    background: #f9fafb;
}