.ctaButton{
    color: #fff;
    background-color: var(--sec-color);
    border: 0;
    outline: none;
}
.ctaButton:hover,.ctaButton:focus,.ctaButton:active{
    opacity: .8;
    background-color:var(--sec-color);
}
.ctaButton.disabled{
    opacity: .8;
    background-color:var(--sec-color);
}