@import url(https://use.typekit.net/uhs8nzw.css);
:root {
    /* --pry-color: #555f73; */
    --pry-color: rgba(28, 11, 48, 0.81);
    --pry-color-light:rgba(85, 95, 115, 0.4);
    --sec-color: #ff8a31;
    --danger-color: #ff2f4a;
    --success-color: #2ccc6c;
  }
html{
  scroll-behavior:smooth;
}
body {
  margin: 0;
  padding: 0;
  font-weight: 300 !important;
  font-family: "proxima-nova", BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Ubuntu", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.mainContainer{
    padding:0;
    margin:0;
    font-size: 18px;
    color:var(--pry-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;
    line-height: 2.6em;
}
h1,h2,h3,h4,h5,h6{
    letter-spacing: 1px;
}
button{
    letter-spacing: 1px;
}
.btn-secondary{
    background-color: var(--pry-color);
    border: none;
    -webkit-transition: 0.3s cubic-bezier(0.2,0.8,0.2,1);
    transition: 0.3s cubic-bezier(0.2,0.8,0.2,1);
}
.btn-secondary:hover{
    background-color:var(--pry-color);
    opacity: .8;
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle{
    background-color:var(--pry-color);
}
a{
    color: var(--pry-color);
    -webkit-transition: 0.3s cubic-bezier(0.2,0.8,0.2,1);
    transition: 0.3s cubic-bezier(0.2,0.8,0.2,1);
    cursor: pointer;
}

a:hover,.dropdown-item.active, .dropdown-item:active{
    font-weight: 600;
    color: inherit;
    background-color: inherit;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .hidden-xs{
        display: none;
    }
}
.mainContainer .modal-container::before{
    opacity: 1;
    background: #f9fafb;
}

.detailView .row{
    padding:100px 10%;
}
.detailView .jumbotron{
    background-color: var(--sec-color);
    border-radius: 0;
    padding: 50px 180px;
    position: relative;
}
.detailView .jumbotron label,.detailView .jumbotron p,.detailView .jumbotron h1,.detailView .jumbotron h2{
    color:#fff;
}
.detailView .jumbotron video{
    margin-top:-80px;
    width: 100%;
    overflow: hidden;
}
.detailView .jumbotron .videoWrapper{
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    left:0;
    top:0px;
    position: absolute;
    display: inline-block;
}
.detailView h2{
    margin-bottom:40px;
    color:var(--pry-color);
    font-weight: 600;
    font-size: 2.5em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: geometricPrecision;
}
.detailView .no-padding{
    padding:0
}
.detailView .secondaryBg{
    background-color: #ff8a31;
    color:#fff;
}
.detailView .secondaryBg h2{
    color:#fff;
}
.detailView .noCommitment .row{
    padding-bottom:0;
}
.detailView .noCommitmentCaption{
    padding-top:100px;
}
.detailView .faq{
    background-color: whitesmoke;
}

.faqBody button{
    box-shadow:4px 4px 11px 0px #b6b8bdc2;
    padding-top: 20px !important;
    padding-bottom:20px !important;
}
.detailView .faqBody{
    padding:20px 0;
}
.detailView .testimonials .card{
    background-color: var(--pry-color);
    color:#fff;
    padding:30px;
}
@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .detailView .jumbotron{
        padding:0;
    }
    .detailView .jumbotron video{
        margin-top:-20px;
    }
    .detailView .jumbotron .display-3{
        font-size: 2.3rem;
    }
    .detailView .jumbotron .display-4{
        font-size: 2rem;
    }
    .detailView .noCommitment .row{
        padding-bottom:100px;
    }
    
}
/****** Globall styling ******/

/*#################### BUGGY-HEADER ####################*/
.accordion{
margin:20px 0;
padding:0.05rem 1.0rem;
}
header{
    display:block;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}
.closed{
    max-height: 0;
}
.open{
    max-height: 100rem;
    margin-top:10px;
}
.accordion-content{
    box-sizing: content-box;
    height: auto;
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property:all;
    transition-property:all;
    z-index: 1;
    -webkit-transition-timing-function: cubic-bezier(0.595, 0.13, 2, 3);
            transition-timing-function: cubic-bezier(0.595, 0.13, 2, 3);
}
::-webkit-scrollbar {
    width: 0px;  /* remove scrollbar space */
    background: transparent;  /* optional: just make scrollbar invisible */
}

.modal-container{
    display: -webkit-flex;
    display: flex;
    position: fixed;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width:100%;
    height:100%;
    opacity: 0;
    left:0;
    top:0;
    background: transparent;
    z-index: 2;
    overflow: hidden;
    -webkit-transform: scale(0);
    -webkit-transition: all 0.2s ease;
    -moz-transform: scale(0);
    -moz-transition: all 0.2s ease;
    transform: scale(0);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.modal-container.show{
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
}
.modal-container::before{
    position: absolute;
    content: "";
    opacity: 0.8;
    top:0;
    left:0;
    background: #0e161d;
    width:100%;
    height: 100%;
}
.modal-body{
    display: inline-block;
    max-width:40%;
    max-height:90%;
    padding-top:30px;
    padding-left:30px;
    padding-right:30px;
    background: #fff;
    overflow-y: auto;
    box-shadow: -5px 5px 100px rgba(95, 103, 109, 0.19);
    border-radius: 20px;
}
@media(max-width:640px){
    .modal-body{
        max-width:90%;
    }
}
.modal-body .content{
    width: 100%;
    display: inline-block;
    position: relative;
}
.modal-container header{
    text-transform: uppercase;
    text-align: center;
    color:#808088;
    display: inline-block;width: 100%;
}
.hidePage{
    margin-left:-120%;
    opacity:0;
    max-height: 0;
    height: 0px;
    float: left;
}
.page{
    width:100%;
    overflow: hidden;
    -webkit-transition:margin-left 0.2s ease-in-out,opacity 0.4s ease-in-out;
    transition:margin-left 0.2s ease-in-out,opacity 0.4s ease-in-out;
}
.stepThree{
    text-align: center;
}

.secButton{
    color: #fff;
    background-color: var(--sec-color);
    border: 0;
    border-radius:4px;
    font-size:16px;
    font-weight: 400;
    padding:8px 16px;
    line-height: 1.5rem;
    outline: none;
}

.secButton:hover,.secButton:focus,.secButton:active{
    opacity: .8;
    background-color:var(--sec-color) !important;
}
.secButton.disabled{
    opacity: .8;
    background-color:var(--sec-color);
    cursor: not-allowed;
}

.progress-bar{
    height: 40px;
    padding: 8px;
    margin: 20px 0;
    font-size: 0.8em;
    border-radius: 8px;
    background-color: var(--sec-color);
}
.applicationForm p{
    color:var(--pry-color) !important;
}
.applicationForm .form-group p{
    line-height: 1.8em;
}
.applicationForm label{
    padding-left:0;
    line-height: 1.5rem;
}
.applicationForm .option{
    padding-left:0;
    line-height: 1.5rem;
}
.custom-file-label{
    padding:10px !important;
    line-height: 1rem !important;
}

.select{
    position: relative;
    display: inline-block;
    width: 100%;
}
.select>input{
    width:100%;
    cursor: pointer;
    background:url(https://storage.googleapis.com/buggy-us/static/media/arrow_down.b357332a.svg) no-repeat right;
    background-size: 15px;
}
.popUp input{display:none}
.select label{
    display:block;
    padding:10px;
    max-height: none;
    font-size: medium;
    font-size: initial;
}
.select .popUp{
    display: inline-block;
    position: absolute;
    left:0;
    max-height: 0;
    overflow: hidden;
    top:0;
    background-color: #fff;
    color: #000;
    width: 100%;
    z-index: 1;
    border-radius: 8px;
    border: 0;
}
.popUp.show{
    max-height: none;
    box-shadow: 0px 2px 5px 2px rgba(214, 216, 224, 0.65);
}
.popUp label{
    padding: 15px 25px;
    border-bottom: 1px solid var(--pry-color-light);
    cursor: pointer;
}
.popUp label:hover{
    background-color: rgba(214, 216, 224, 0.65)
}
.popUp label:last-of-type{
    border-bottom:none;
}
@media(max-width:640px){
    button{
        padding: 0.1em 0.5em;
    }
}
.ctaButton{
    color: #fff;
    background-color: var(--sec-color);
    border: 0;
    outline: none;
}
.ctaButton:hover,.ctaButton:focus,.ctaButton:active{
    opacity: .8;
    background-color:var(--sec-color);
}
.ctaButton.disabled{
    opacity: .8;
    background-color:var(--sec-color);
}
input{
    display: inline-block;
    padding: 7px 10px;
    margin-bottom:10px;
    color: #515861;
    border: 0;
    outline: none;
    border-bottom: 1px solid rgba(173, 182, 197, 0.38);
}
.error-state input{
    border-bottom-color:#ff2f4a;
}
.full-width{
    width:100%;
}

input:focus { 
   border-bottom: 4px solid rgba(173, 182, 197, 0.8);
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.7; /* Firefox */
}
::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.7; /* Firefox */
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 0.7; /* Firefox */
}
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
opacity: 0.7;
}
  
::-ms-input-placeholder { /* Microsoft Edge */
opacity: 0.7;
}
label{
    display: inline-block;
    width:100%;
    color: #515861 !important;
    padding-left: 10px;
    margin-bottom:0;
    opacity: 0.8;
    font-weight: 500;
    font-size: 0.8em;
    max-height: 500px;
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
    -webkit-transition-property: max-height;
    transition-property: max-height;
}
label.hide{
    max-height: 0;
}
.error-state label{
    color:#ff2f4a !important;
}

.policies.row{
    padding:100px 10%;
}
.policies .media{
    padding-top:10px;
    padding-bottom:10px;
}

.wavView.row{
    padding:100px 10%;
}
.mainFooter{
    padding:100px 10%;
}
.mainFooter .nav-link{
    padding-left:0;
    color:var(--pry-color);
    font-weight: 400;
}
.mainFooter .nav-link:hover{
    font-weight: 600;
}
.mainNavbar{
    background-color: var(--sec-color);
    color:#fff;
    padding:1rem 15rem;
    z-index: 1;
    border-bottom: 4px solid #fff;
}
.mainNavbar .navbar-nav .nav-link{
    color:#fff;
    font-weight: 400;
    letter-spacing: 1px;
}
.mainNavbar .nav-link:hover{
    font-weight: 600;
}
@media (max-width:640px){
    .innerNav{
        display: inline-block;
    }
    .mainNavbar{
        padding:.5rem .5rem;
    }
}


button{
    display: inline-block;
    border-radius: 0.4em;
    padding: 0.5em 1em !important;
    color: #fff;
    background-color: var(--sec-color);
    border: 0;
    outline: none;
    margin-bottom: 5px;
}
@media(max-width:640px){
    button{
        padding: 0.1em 0.5em;
    }
}

button.xs{
    font-size: 0.75em;
}
button.md{
    font-size: 1.2em;
}
button.success{
    background-color: var(--success-color);
}
button.danger{
    background-color: var(--danger-color);
}
button.cancel{
    background-color:var(--pry-color-light);
}

.pickupForm.row{
    padding:100px 10%;
}
.carListView .item{
    padding-top:50px;
    padding-bottom:50px;
}
.carListView p,.carListView header,.carListView span{
    color:black;
}
.carListView p{
    font-weight: 400;
}
.carListView .title{
    text-align: left;
}
.carListView .item img{
    text-align: center;
    max-height: 150px;
    display: block;
    margin-bottom: 20px;
}
@media(max-width:640px){
    button{
        padding: 0.1em 0.5em;
    }
}
.hidePage{
    margin-left:-120%;
    opacity:0;
    max-height: 0;
    height: 0px;
    float: left;
}
.page{
    width:100%;
    overflow: hidden;
    -webkit-transition:margin-left 0.2s ease-in-out,opacity 0.4s ease-in-out;
    transition:margin-left 0.2s ease-in-out,opacity 0.4s ease-in-out;
}
.bookCarConfirmation{
    color:#000;
    text-align: center;
}
.bookCarConfirmation img{
    display: inline-block;
    text-align: center;
}


.faqView.row{
    padding:100px 10%;
}
.faqView.row > div{
    padding:20px 0;
}
.nav-link.active{
    font-weight: 600;
}
.faqView.row .faqBody {
    padding-top:15px;
    padding-bottom:15px;
}
